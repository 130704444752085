const softProj = [
  // Software
  {
    title: "Project management app",
    image: "signin.png",
    date: "2023",
    goal: "Facilitate team's work",
    tools: ["Java", "Java Swing", "Agile methods"],
    category: "Software Development",
    details: [
      "Creation of individual views showing the user its tasks and their advancement",
      "Creation of group views showing the group the arborescence of the tasks and the global advancement",
      "Project done using the AGILE method with a team of 8 student",
    ],
  },
  {
    title: "Minishell",
    date: "2023",
    goal: "Create a simplified command interpreter",
    tools: ["C", "POSIX"],
    image: "minishell.png",
    category: "Software Development",
    details: [
      "Management of launched processes (ability to list them, stop them, put them in foreground or background)",
      "Redirection and pipeline management",
      "Control of foreground processes via ctrl+z or ctrl+c",
    ],
  },
  {
    title: "Minichat",
    date: "2023",
    goal: "Create an interactive messaging system",
    image: "minichat.png",
    tools: ["C", "POSIX"],
    category: "Software Development",
    details: [
      "Creation of a terminal-based interface",
      "Use of pseudonyms and predefined messages",
      "Allows participants to share messages interactively",
    ],
  },
  {
    title: "Arkanoid",
    date: "2023-2024",
    goal: "Create an arkanoid game, using mainly functionnal programming",
    tools: ["Ocaml"],
    image: "arkanoid.png",
    category: "Game Development",
    details: [
      "Management of the physic of the ball : bouncing on obstacles, gravity, and slow acceleration",
      "Creation of the pad",
      "Creation of the plateau using matrixes",
    ],
  },
  {
    title: "Schema Table Creator",
    date: "2023-2024",
    category: "Software Development",
    tools: ["Eclipse", "Python", "Acceleo", "ATL", "Sirius", "OCL"],
    image: "projet_idm.png",
    goal: "Create a set of tools for creating schema tables and automating associated calculations",
    details: [
      "Feature allowing users to create schema table models",
      "Feature allowing users to add constraints to a column and calculate the values of a column using other columns",
      "Feature allowing users to import their data into schema tables using a specific interface",
    ],
  },
  {
    title: "Simplified Hadoop System",
    date: "2023",
    goal: "Create a distributed data processing system",
    tools: ["Java", "Bash"],
    image: "hagidoop.png",
    category: "Software Development",
    details: [
      "Development of a deployment script to launch servers on nodes and clean up nodes after use automatically",
      "Use of concurrent programming, such as threads and locks",
      "Use of sockets and RMI",
    ],
  },
  {
    title: "Router",
    date: "2022",
    goal: "Implement, evaluate, and compare different methods for storing and utilizing a router's routing table.",
    tools: ["Ada 95"],
    image: "pim_terminal_view.webp",
    category: "",
    details: [
      "Creation of a router, that forwards packets to the correct outgoing interface based on routing table information",
      "Designing and implementing storage structures for the routing table",
      "Incorporating cache to improve the efficiency of the router",
    ],
  },
];

export default softProj;
