import "./socialLine.css";
import CVAnglais from "../Documents/CV.pdf";
import Mail from "../images/logo_mail.svg";
import LinkedIn from "../images/logo_linkedin.svg";
import CVImage from "../images/CV.svg";

function SocialLink({ lien, image, alt }) {
  return (
    <a className="social-link" href={lien} target="_blank norefferer">
      <img
        className="filteredImg"
        src={image}
        alt={alt}
        height="50px"
        width="50px"
        loading="lazy"
      />
    </a>
  );
}

function SocialLine() {
  return (
    <div className="social-line">
      <SocialLink
        lien="https://www.linkedin.com/in/claire-scheyder/"
        image={LinkedIn}
        alt="LinkedIn"
      />

      <SocialLink
        lien="mailto:clairescheyder1@gmail.com"
        image={Mail}
        alt="Mail"
      />

      <SocialLink lien={CVAnglais} image={CVImage} alt="CV" />
    </div>
  );
}

export default SocialLine;
