const skillsData = [
  { category: "Programming Languages", name: "C", image: "logo_C.png" },
  { category: "Programming Languages", name: "Java", image: "logo_java2.png" },
  {
    category: "Programming Languages",
    name: "Python",
    image: "logo_python.png",
  },
  { category: "Programming Languages", name: "Ocaml", image: "logo_ocaml.png" },
  {
    category: "Programming Languages",
    name: "Ada",
    image: "logo_ada_trans.png",
  },
  {
    category: "Programming Languages",
    name: "Matlab",
    image: "logo_matlab.png",
  },
  { category: "Programming Languages", name: "Julia", image: "logo_julia.png" },
  {
    category: "Web Languages And Frameworks",
    name: "HTML",
    image: "logo_html.png",
  },
  {
    category: "Web Languages And Frameworks",
    name: "CSS",
    image: "logo_css.png",
  },
  {
    category: "Web Languages And Frameworks",
    name: "React",
    image: "logo_react.png",
  },
  {
    category: "Programming Languages",
    name: "Shell",
    image: "logo_shell_trans.png",
  },
  { category: "Programming Languages", name: "SQL", image: "logo_sql.png" },
  {
    category: "Project Management Tools",
    name: "Github",
    image: "logo_github.png",
  },
  {
    category: "Project Management Tools",
    name: "Jira",
    image: "logo_jira.jpg",
  },
  {
    category: "Project Management Tools",
    name: "Trello",
    image: "logo_trello.png",
  },
  {
    category: "Project Management Tools",
    name: "ClickUp",
    image: "logo_clickup.png",
  },
  { category: "Game Development", name: "Unity", image: "logo_unity.png" },
  { category: "Modeling Languages", name: "UML", image: "logo_uml.png" },
  { category: "Modeling Languages", name: "OCL", image: "logo_OCL.svg" },
  { category: "Modeling Languages", name: "ATL", image: "logo_atl.png" },
  {
    category: "Modeling Languages",
    name: "Acceleo",
    image: "logo_acceleo.png",
  },
  { category: "Modeling Languages", name: "Sirius", image: "logo_sirius.png" },
  { category: "Modeling Languages", name: "XText", image: "logo_xtext.png" },
  { category: "Soft Skills", name: "Teamwork", image: "logo_teamwork.png" },
  { category: "Soft Skills", name: "Autonomy", image: "logo_autonomy.png" },
  { category: "Soft Skills", name: "Fast-learner", image: "logo_learning.png" },
  {
    category: "Soft Skills",
    name: "Adaptability",
    image: "logo_adaptability.png",
  },
  {
    category: "Soft Skills",
    name: "Problem solving",
    image: "logo_solver.png",
  },
  {
    category: "Web Languages And Frameworks",
    name: "Django",
    image: "logo_django.png",
  },
];

export default skillsData;
