import "./Header.css";

function Header() {
  return (
    <header>
      <h1>Claire SCHEYDER</h1>
      <div className="tabbed-menu">
        <nav>
          <ul className="tabs">
            <div className="tab">
              <li>
                <a href="#welcome">Welcome</a>
              </li>
            </div>
            <div className="tab">
              <li>
                <a href="#projects">Projects</a>
              </li>
            </div>
            <div className="tab">
              <li>
                <a href="#skills">Skills</a>
              </li>
            </div>
            <div className="tab">
              <li>
                <a href="#internships">Internships</a>
              </li>
            </div>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
