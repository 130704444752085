import "./Welcome.css";
import SocialLine from "../../components/socialLine";
import { ReactTyped } from "react-typed";
import { FaGraduationCap } from "react-icons/fa";
import { TbTargetArrow } from "react-icons/tb";
import PP from "../../images/PP.jpg";

function Welcome() {
  return (
    <section id="welcome" className="tab-content">
      <div className="cadre-photo">
        <div className="oval-container">
          <img src={PP} alt="Me" width="300px" class="oval-photo" />
        </div>
        <p className="name">
          <b>
            Claire Scheyder <br /> ----
          </b>
        </p>
        <SocialLine />
      </div>
      <div className="droite">
        <div className="text-intro">
          <h1>
            <ReactTyped
              startWhenVisible
              strings={["WELCOME"]}
              typeSpeed={50}
              backSpeed={0}
              loop={false}
              showCursor={true}
              className="typed-text"
            />
          </h1>
          <p>
            <FaGraduationCap
              style={{ marginRight: "8px", verticalAlign: "middle" }}
            />
            Computer Science Engineering Student at{" "}
            <a
              href="https://www.enseeiht.fr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>ENSEEIHT</b>
            </a>{" "}
            &{" "}
            <a
              href="https://www.uni-bremen.de"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>University of Bremen </b>
            </a>{" "}
            <br />
            <br />
            <TbTargetArrow
              style={{ marginRight: "8px", verticalAlign: "middle" }}
            />
            Specialized in <b>Software</b> & <b>Data</b>
          </p>
        </div>
      </div>
    </section>
  );
}

export default Welcome;
