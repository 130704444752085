import "./Internships.css";
import LibraryReport from "../../Documents/internship_report.pdf";
import LibraryImage from "../../images/internships/library.png";
import FuturapplyImage from "../../images/internships/writing_computer.jpg";
import FuturapplyReport from "../../Documents/report_futurapply.pdf";

/*TODO : 
- rendre ça plus responsive
*/

function Internship(props) {
  return (
    <div className="internship-container-rev">
      <div className="img-diag-container-rev">
        <img src={props.src} alt={props.alt} loading="lazy" />
      </div>
      <div className="info-container">
        <div className="texte">
          <h2>{props.name}</h2>
          <p>
            <em>Skills I developed through this experience : </em>
          </p>
          <ul>
            {props.lessons.map((lesson, index) => (
              <li key={index}>{lesson}</li>
            ))}
          </ul>
          <p>
            For further information, you can download my report{" "}
            <a href={props.link} target="_blank" rel="noreferrer">
              here
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}

function Internships() {
  return (
    <section id="internships" className="tab-content">
      <h2 className="section-title">Internships</h2>
      <div className="internship-container">
        <div className="img-diag-container">
          <img
            src={FuturapplyImage}
            alt="Someone writing on a computer"
            loading="lazy"
          />
        </div>
        <div className="info-container">
          <div className="texte">
            <h2>Entrepreneurial Internship - Futurapply</h2>
            <p>
              <em>Skills I developed through this experience : </em>
            </p>
            <ul>
              <li>Entrepreneurship</li>
              <li>Autonomy</li>
              <li>Web Development skills</li>
            </ul>
            <p>
              For further information, you can download my report{" "}
              <a href={FuturapplyReport} target="_blank" rel="noreferrer">
                here
              </a>
              .
            </p>
          </div>
        </div>
      </div>
      <Internship
        name="Internship at Saverne library"
        src={LibraryImage}
        className="reversed"
        alt="A library"
        lessons={[
          "Teamwork",
          "Task prioritizing",
          "Presenting results clearly and effectively at meetings",
          "Creating visual supports to help future work",
        ]}
        link={LibraryReport}
      />
    </section>
  );
}

export default Internships;
