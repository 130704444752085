import React from "react";
import "./MySwiper.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import webDevProj from "../constantes/projets/webDev.js";
import softProj from "../constantes/projets/projets.js";
import dataProj from "../constantes/projets/dataProj.js";
import otherProj from "../constantes/projets/otherProj.js";

function Card2(props) {
  const imagePath = require(`../images/projets/${props.image}`);
  return (
    <div className="card swiper-slide">
      <div className="card__image">
        <img src={imagePath} alt={props.title} loading="lazy" />
      </div>
      <div className="card__content">
        <span>
          <h3 className="card__title"> {props.title}</h3>
        </span>
        <div className="card__name">
          <span>
            <b>Goal:</b> {props.goal}
          </span>
          <p className="card__text">
            <ul>
              {props.details.map((detail, index) => (
                <li key={index}>{detail}</li>
              ))}
            </ul>
          </p>
          {/* <button className="card__btn">View More</button> */}
        </div>
      </div>
      <div className="project-tags">
        {/* <b>Made with:</b> */}
        {props.tools.map((tool, index) => (
          <span key={index} className="project-tag">
            {tool}
          </span>
        ))}
      </div>
    </div>
  );
}

function MySwiper({ projets }) {
  return (
    <section className="swiper mySwiper">
      <div className="swiper-wrapper">
        <Swiper
          modules={[Navigation, Pagination, A11y]}
          spaceBetween={50}
          breakpoints={{
            1024: {
              slidesPerView: 3,
            },
          }}
          navigation
          pagination={{ clickable: true }}
          centeredSlides={false}
          centerInsufficientSlides={true}
        >
          {projets.map((project) => (
            <SwiperSlide>
              <Card2 key={project.title} {...project} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="swiper-pagination"></div>
    </section>
  );
}

function ProjectSection() {
  return (
    <section id="projects">
      <h1 className="section-title">My projects</h1>
      <h2>Data Analysis</h2>
      <MySwiper projets={dataProj} />
      <h2> Software Engineering</h2>
      <MySwiper projets={softProj} />
      <h2> Web Development</h2>
      <MySwiper projets={webDevProj} />
      <h2>Miscellaneous</h2>
      <MySwiper projets={otherProj} />
    </section>
  );
}

export default ProjectSection;
