const webDevProj = [
  // Web dev
  {
    title: "Portfolio Website",
    date: "2023-present",
    goal: "Showcase my skills and projects using a portfolio website",
    tools: ["React", "HTML", "CSS"],
    image: "eportfolio.png",
    category: "Web Development",
    details: [
      "Creation of responsive layouts for the website",
      "Implementation of a clean and simple design that effectively conveys information",
      "Creation of a modular system that allows for easy modification or addition of information",
    ],
  },
  {
    title: "FuturApply - Entrepreneurial project",
    date: "2023-present",
    goal: "Facilitate the gestion and the writing of cover letters",
    tools: ["React", "Django (Python)", "CSS", "Jira"],
    image: "futurapply.png",
    category: "Web Development",
    details: [
      "Creation of a text editor that allows users to write and modify cover letters",
      "Addition of tools to make the writing process faster",
      "Implementation of a payment method using Stripe",
      'More information in the "Internships" section',
    ],
  },
  {
    title: "Internship finder",
    date: "2024",
    goal: "Help student to find internships",
    tools: ["Figma", "React", "CSS", "Java EE"],
    image: "internship_finder.png",
    category: "Web Development",
    details: [
      "Creation of the Figma and database architecture",
      "Functionality enabling recruiters to create, list, and edit job offers",
      "Job search feature that allows students to search for job offers using filters",
      "Possibility to save offers for future reference",
    ],
  },
  {
    title: "CRUD Website",
    date: "2024",
    goal: "Develop a CRUD",
    tools: ["React", "HTML", "CSS", "Django", "MongoDB"],
    image: "crud.png",
    category: "Web Development",
    details: [
      "Connection of the website to a MongoDB database using Djongo.",
      "Creation of public pages displaying the articles",
      "Implementation of a back office with authentication that enables the creation, editing, and deletion of articles",
    ],
  },
];

export default webDevProj;
