const otherProj = [
  {
    //Other
    title: "Scientific article",
    date: "2024",
    goal: "Write a scientific article, introducing Safety Critical Systems and Formal Methods ",
    tools: ["Overleaf", "Latex"],
    image: "debut_article.png",
    category: "Formal Methods",
    details: [
      "Description and shematization of the lifecycle of a Safety Critical System",
      "Research and writing of a state-of-the-art",
      "Evaluation of the benefits and challenges of AI in this field",
    ],
  },
];

export default otherProj;
