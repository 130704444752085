import React from "react";
import "./Skills.css";
import skillsData from "../../constantes/skillsData";
import { useState } from "react";
import softskillsImage from "/home/claire/Documents/Projets_info/eportfolio_design_type_CV/eportfolio/src/images/4_softskills.png";

function Skill(props) {
  const imagePath = require(`../../images/logos_skills/${props.image}`);

  return (
    <div className="skill">
      <img
        src={imagePath}
        alt={`${props.name} logo`}
        width="90px"
        height="90px"
        loading="lazy"
      />
      <span>{props.name}</span>
    </div>
  );
}

function Skills() {
  const [selectedCategory, setSelectedCategory] = useState(
    "Programming Languages"
  );

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const filteredSkills = skillsData.filter(
    (skill) => skill.category === selectedCategory
  );

  return (
    <section id="skills" className="tab-content">
      <h1 className="section-title">Skills</h1>
      <h2>Soft Skills</h2>
      <img
        src={softskillsImage}
        alt="Softskills : Adaptability, Good Work Ethic, Problem-solving, Teamwork"
        loading="lazy"
        className="softskills_img"
      ></img>
      <h2>Hard Skills</h2>
      <div className="skills_container">
        <div className="categories">
          {[
            "Programming Languages",
            "Web Languages And Frameworks",
            // "Game Development",
            "Project Management Tools",
            "Modeling Languages",
            //"Soft Skills",
          ].map((category) => (
            <button
              key={category}
              className={`category ${
                selectedCategory === category ? "selected" : ""
              }`}
              onClick={() => handleCategoryClick(category)}
            >
              {category}
            </button>
          ))}
        </div>
        <div className="skills">
          {filteredSkills.map((skill) => (
            <Skill key={skill.name} name={skill.name} image={skill.image} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Skills;
