import React from "react";
import "./App.css";
import Header from "./components/Header";
import Welcome from "./pages/Welcome/Welcome";
import Skills from "./pages/EnginneringCourse/Skills";
import Internships from "./pages/CareerDevelopment/Internships";
import ProjectSection from "./components/MySwiper";

const App = () => {
  return (
    <div>
      <Header />
      <main className="main-content">
        <Welcome />
        <ProjectSection />
        <Skills />
        <Internships />
      </main>
    </div>
  );
};

export default App;
