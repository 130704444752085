const dataProj = [
  {
    // Data Analysis
    title: "Image Classifier",
    date: "2024",
    goal: "Classify landscape's images using a convolutive neural network",
    tools: ["Python", "Tensorflow", "Keras", "Google Colab"],
    image: "classifier.png",
    category: "Data Analysis",
    details: [
      "Scrapping of images to constitute a database",
      "Creation and optimization of a convolutive neural network",
      "Evaluation of the classifier using several factors such as global accuracy, recall or F1-score",
    ],
  },
  {
    title: "Study of the topology of a satellite network",
    date: "2024",
    goal: "Analyze the topology of a satellite network with varying densities and distances",
    tools: ["Julia"],
    image: "graphe.png",
    category: "Data Analysis",
    details: [
      "Generation of graphs and plots to visualize the network from coordinates from a csv file",
      "Study of the distribution of degrees and clustering coefficients",
      "Identification of cliques in the swarm graphs",
    ],
  },
  {
    title: "Project on speech recognition",
    date: "2021-2022",
    goal: "Improve speech recognition for people with hearing deficiency thanks to linguistic models",
    tools: ["Python"],
    image: "tipe.png",
    category: "Data Analysis",
    details: [
      "Comparison of the efficiency of several algorithms using the bigram method",
      "Optimization for the use of hearing impaired people",
    ],
  },
];

export default dataProj;
